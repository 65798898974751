import { Button, Col, DatePicker, Divider, Input, Popconfirm, Row, Select, Spin } from 'antd';
import {  PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { InvestorAPI } from '../../../Api/InvestorAPI';
import { BeneficiaryAPI } from '../../../Api/BeneficiaryAPI';
import { useBeneficiaries } from '../../../Hooks/useBeneficiaries';

import { IBeneficiaries } from './IBeneficiaries';
import { Beneficiaries as BeneficiariesModel } from '../../../Models/Beneficiaries';

import './Beneficiaries.scss';
import FormLayout from '../../FormLayout/FormLayout';

const { Option } = Select;

const Beneficiaries = ({
	baseUrl,
	translations,
	getAuthToken,
	unlockNextStep
}: IBeneficiaries) => {

	const investorAPI = InvestorAPI(baseUrl, getAuthToken);
	const beneficiaryAPI = BeneficiaryAPI(baseUrl, getAuthToken);
	const {
		loading,
		beneficiaries,
		errors,
		nationalitiesList,
		addRow,
		getInfoTitulo,
		handleChangeData,
		handleSelectOrDatePicker,
		getInfo,
		deleteRow,
		saveData
	} = useBeneficiaries({
		translations,
		investorAPI,
		beneficiaryAPI,
		unlockNextStep
	});

	return (
		<FormLayout
			translations={translations}
			loading={loading}
			title={translations.tituloBenerficiarios}
			subTitle={<div>{translations.encabezadoBeneficiarios}<span className="tooltip-benef" onClick={getInfoTitulo}>1</span></div>}
			onClick={saveData}
			nextStep={unlockNextStep}
			enableNextStep={(beneficiaries.length > 0 && beneficiaries[0].id > 0) ? true : false}
		>
			<Button
				className="save-btn"
				onClick={addRow}
			>
				<PlusCircleOutlined />{translations.masBeneficiarios}
			</Button>
			{beneficiaries.map((x: BeneficiariesModel, i: number) =>
					<Row gutter={[16, 16]}>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.nombreBeneficiario}</label>
							<Input
								type="text"
								name="name"
								value={x.name}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].name ? 'error' : ''}
							/>
							<span className={`error ${errors[i].name ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.apellidosFirmante}</label>
							<Input
								type="text"
								name="lastName"
								value={x.lastName}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].lastName ? 'error' : ''}
							/>
							<span className={`error ${errors[i].lastName ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.fechaNacimiento}</label>
							<DatePicker
								allowClear={false}
								className="input-field"
								name="birthDate"
								value={x.birthDate ? moment(x.birthDate) : undefined}
								format="DD/MM/YYYY"
								onChange={handleSelectOrDatePicker("birthDate", i)}
								status={errors[i].birthDate ? 'error' : ''}
							/>
							<span className={`error ${errors[i].birthDate ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.direccionDomicilio}</label>
							<Input
								type="text"
								name="address"
								value={x.address}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].address ? 'error' : ''}
							/>
							<span className={`error ${errors[i].address ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.identificacionFiscalBeneficiario}</label>
							<Input
								type="text"
								name="nif"
								value={x.nif}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].nif || errors[i].notValidDocument ? 'error' : ''}
							/>
							<span className={`error ${errors[i].nif ? 'show' : null}`}>{translations.completeCampo}</span>
							<span className={`error ${errors[i].notValidDocument ? 'show' : null}`}>{translations.invalidIdDocument}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.expirationDate}</label>
							<DatePicker
								allowClear={false}
								className="input-field"
								name="documentExpiration"
								value={x.documentExpiration ? moment(x.documentExpiration) : undefined}
								format="DD/MM/YYYY"
								onChange={handleSelectOrDatePicker("documentExpiration", i)}
								status={errors[i].documentExpiration || errors[i].notValidExpirationDate ? 'error' : ''}
							/>
							<span className={`error ${errors[i].documentExpiration ? 'show' : null}`}>{translations.completeCampo}</span>
							<span className={`error ${errors[i].notValidExpirationDate ? 'show' : null}`}>{translations.invalidDate}</span>
						</Col>
						<Col xs={24} md={12} lg={12} className="titles">
							<label className="required"> {translations.paisResidenciaFiscal}</label>
							<Input
								type="text"
								name="countryTaxResidence"
								value={x.countryTaxResidence}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].countryTaxResidence ? 'error' : ''}
							/>
							<span className={`error ${errors[i].countryTaxResidence ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.nacionalidad}</label>
							<Select
								className="input-field"
								value={x.nationality}
								onChange={handleSelectOrDatePicker('nationality', i)}
								status={errors[i].nationality ? 'error' : ''}
								placeholder="Seleccione una opción"
								showSearch
								filterOption={(input, option) =>
									(option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
								}
							>
								{nationalitiesList.map((nationality: any)=> {
									return <Option value={nationality.name_lang2}>{nationality.name_lang2}</Option>
								})}
							</Select>
							<span className={`error ${errors[i].nationality ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.porcentajeParticipacion}</label>
							<Input
								type="text"
								name="participationPercentage"
								value={x.participationPercentage}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].participationPercentage ? 'error' : ''}
							/>
							<span className={`error ${errors[i].participationPercentage ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={8} lg={8} className="titles">
							<label className="required"> {translations.prpBeneficiario} <span className="tooltip-benef" onClick={getInfo}>2</span></label>
							<Input
								type="text"
								name="prp"
								value={x.prp}
								onChange={(e) => handleChangeData(e, i)}
								status={errors[i].prp ? 'error' : ''}
							/>
							<span className={`error ${errors[i].prp ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={4} lg={4} className="titles">
							<Popconfirm
								title={translations.eliminarBeneficiario}
								okText={translations.si}
								cancelText={translations.no}
								onConfirm={() => deleteRow(i)}
							>
								<Button danger className="dlt-btn-benef">{translations.delete}</Button>
							</Popconfirm>
						</Col>
						<Divider />
					</Row>
			)}
		</FormLayout>
	);
};

export default Beneficiaries;
