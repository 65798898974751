import { Button, Checkbox, Col, DatePicker, Divider, Input, Radio, Result, Row, Select, Spin, Tooltip } from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { activityOptionsLanguages, incomeOptionsLanguages, sectorOptionsLanguages, sourceOptionsLanguages } from './ActivityLists';

import { InvestorAPI } from '../../../Api/InvestorAPI';

import { usePhysicalInvestorForm } from '../../../Hooks/usePhysicalInvestorForm';

import { IPhysicalInvestorForm } from './IPhysicalInvestorForm';

import './PhysicalInvestorForm.scss';
import FormLayout from '../../FormLayout/FormLayout';
import { ContractAPI } from '../../../Api/ContractAPI';

const { Option } = Select;

const PhysicalInvestorForm = ({
	baseUrl,
	translations,
	getAuthToken,
	unlockNextStep
}: IPhysicalInvestorForm) => {
	const investorAPI = InvestorAPI(baseUrl, getAuthToken);
	const contractAPI = ContractAPI(baseUrl, getAuthToken);
	const {
		loading,
		noContent,
		errors,
		investorData,
		nationalitiesList,
		activityInList,
		sourceInList,
		onChangeBirthDate,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		handleCheckedData,
		saveData,
		validateData,
		contractLanguage
	} = usePhysicalInvestorForm({
		translations,
		investorAPI,
		contractAPI,
		unlockNextStep
	});

	return (
		<FormLayout 
			translations={translations}
			loading={loading}
			title={translations.formularioInversor}
			subTitle={translations.encabezadoFormulario}
			onClick={saveData}
			disabledSaveButton={noContent}
			nextStep={unlockNextStep}
			enableNextStep={validateData(false)}
		>	
				{!noContent ?
					<>
						{/*Esta sección se repite, según la cantidad de inversores seleccionados */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12} lg={12} className="titles">
								<p>
									<label className="required"> {translations.fechaNacimiento} </label>
									<Tooltip placement="topLeft" title={translations.tooltipFechaNac} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
								<DatePicker
									allowClear={false}
									className="input-field"
									name="birthDate"
									value={investorData.birthDate ? moment(investorData.birthDate) : undefined}
									format="DD/MM/YYYY"
									onChange={onChangeBirthDate}
									status={errors.birthDate ? 'error' : ''}
								/>
								<span className={`error ${errors.birthDate ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.lugarnacimiento}</label>
								<Input placeholder={translations.placeholderLugarNac} type="text"
									name="birthPlace"
									value={investorData.birthPlace}
									onChange={handleChangeData}
									status={errors.birthPlace ? 'error' : ''}
								/>
								<span className={`error ${errors.birthPlace ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.nacionalidad}</label>
								<Select
									className="input-field"
									value={investorData.nationality}
									onChange={handleSelectData('nationality')}
									status={errors.nationality ? 'error' : ''}
									placeholder="Seleccione una opción"
									showSearch
									filterOption={(input, option) =>
									  (option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
									}
								>
									{nationalitiesList.map((nationality: any)=> {
										return <Option value={nationality.name_lang2}>{nationality.name_lang2}</Option>
									})}
								</Select>
								<span className={`error ${errors.nationality ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.domicilioPrincipal}</label>
								<Input type="text"
									name="mainResidence"
									value={investorData.mainResidence}
									onChange={handleChangeData}
									status={errors.mainResidence ? 'error' : ''}
								/>
								<span className={`error ${errors.mainResidence ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required"> {translations.prefix}</label>
								<Input type="text"
									name="phonePrefix"
									value={investorData.phonePrefix}
									onChange={handleChangeData}
									status={errors.phonePrefix ? 'error' : ''}
								/>
								<span className={`error ${errors.phonePrefix ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required"> {translations.numeroTelefonoFisica}</label>
								<Input type="text"
									name="phoneNumber"
									value={investorData.phoneNumber}
									onChange={handleChangeData}
									status={errors.phoneNumber ? 'error' : ''}
								/>
								<span className={`error ${errors.phoneNumber ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required"> {translations.direccionEmailFisica}</label>
								<Input type="text"
									name="emailContact"
									value={investorData.emailContact}
									onChange={handleChangeData}
									status={errors.emailContact ? 'error' : ''}
								/>
								<span className={`error ${errors.emailContact ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label>{translations.direccionPostalFisica}</label>
								<Input type="text"
									name="postalAddress"
									value={investorData.postalAddress}
									onChange={handleChangeData}
								/>
							</Col>
							<Col xs={12} md={6} lg={6}>
								<label className="required">
									{translations.documentType}
								</label>
								<Select
									className="input-field"
									value={investorData.documentType}
									onChange={handleChangeData}
									placeholder={translations.documentType}
									status={errors.documentType ? "error" : ""}
								>
									<Option value="dni">DNI</Option>
									<Option value="nif">NIF</Option>
									<Option value="passport">{translations.passport}</Option>
									<Option value="residenceCard">{translations.residentCard}</Option>
								</Select>
							</Col>
							<Col xs={12} md={6} lg={6}>
								<label className="required">
									{translations.documentNumber}
								</label>
								{investorData.documentType === "dni" ? 
									<Input
										className="input-field"
										value={investorData.dni || investorData.nif || investorData.passport || investorData.residenceCard}
										onChange={handleChangeData}
										placeholder={translations.documentNumber}
										status={errors.document ? "error" : "" }
									/> : null
								}
								{!investorData.documentType &&
									<Input
										className="input-field"
										disabled
										placeholder={translations.documentNumber}
									/>
								}
								<span className={`error ${errors.document ? 'show' : null}`}>{translations.completeCampo}</span>
								<span className={`error ${errors.documentFormat ? 'show' : null}`}>{translations.invalidIdDocument}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required"> {translations.expirationDate}</label>
								<DatePicker
									allowClear={false}
									className="input-field"
									name="docValidThru"
									value={investorData.docValidThru ? moment(investorData.docValidThru) : undefined}
									format="DD/MM/YYYY"
									onChange={handleChangeDate}
									status={errors.docValidThru ? 'error' : ''}
								/>
								<span className={`error ${errors.docValidThru ? 'show' : null}`}>{translations.completeCampo}</span>
								<span className={`error ${errors.documentExpirationInvalid ? 'show' : null}`}>{translations.notValidExpirationDate}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<p>
									<label className="required"> {translations.paisResidenciaFiscal} </label>
									<Tooltip placement="topLeft" title={translations.tooltipPaisResidenciaFiscal} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
								<Input type="text"
									name="countryTaxResidence"
									value={investorData.countryTaxResidence}
									onChange={handleChangeData}
									status={errors.countryTaxResidence ? 'error' : ''}
								/>
								<span className={`error ${errors.countryTaxResidence ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<p>
									<label className="required"> {translations.identificacionFiscal} </label>
									<Tooltip placement="topLeft" title={translations.tooltipIdentificacionFiscal} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
								<Input type="text"
									name="taxIdentification"
									value={investorData.taxIdentification}
									onChange={handleChangeData}
									status={errors.taxIdentification ? 'error' : ''}
								/>
								<span className={`error ${errors.taxIdentification ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={24} lg={24} className="titles">
								<label>{translations.encabezadoRazon}</label>
								<Row className="nif-radio-group">
									<Radio.Group
										name="notNIFReason"
										value={investorData.notNIFReason}
										onChange={handleChangeData}
									>
										<Radio className="nif-radio" value={1}> {translations.razonA}</Radio>
										<Radio className="nif-radio" value={2}>{translations.razonB}</Radio>
										{/*  Este input, sólo se habilita si se selecciona la Razón B */}
										<Input
											className="nif-radio-reason"
											placeholder={translations.placeHolderRazon}
											disabled={investorData.notNIFReason !== 2}
											name="notNIFDetailReason"
											value={investorData.notNIFDetailReason}
											onChange={handleChangeData}
										/>
										<Radio className="nif-radio" value={3}>{translations.razonC}</Radio>
									</Radio.Group>
								</Row>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.employmentSituation}</label>
								<Select
									className="input-field"
									value={investorData.situation}
									onChange={handleSelectData('situation')}
									status={errors.situation ? 'error' : ''}
									placeholder="Seleccione una opción"
								>
									{activityOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
								</Select>
								<span className={`error ${errors.situation ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
							</Col>
								{!activityInList ? 
								<>
									<Col xs={24} md={6} lg={6} className="titles">
										<label className="required"> {translations.activitySector} </label>
										<Select
											className="input-field"
											value={investorData.sector}
											onChange={handleSelectData('sector')}
											status={errors.sector ? 'error' : ''}
											placeholder="Seleccione una opción"
											showSearch
											filterOption={(input, option) =>
												(option!.children as unknown as string).includes(input)
											}
										>
											{sectorOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
											<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
										</Select>
										<span className={`error ${errors.sector ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
									</Col>
									<Col xs={24} md={6} lg={6} className="titles">
										<label className="required">{translations.otherActivity}</label>
										<Input 
											name="otherSector"
											value={investorData.otherSector}
											onChange={handleChangeData}
											/>
									</Col>							
								</>
								:
								<>
									<Col xs={24} md={12} lg={12} className="titles">
										<label className="required"> {translations.activitySector} </label>
										<Select
											className="input-field"
											value={investorData.sector}
											onChange={handleSelectData('sector')}
											status={errors.sector ? 'error' : ''}
											placeholder="Seleccione una opción"
										>
											{sectorOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
											<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
										</Select>
										<span className={`error ${errors.sector ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
									</Col>
								</>
							}
							{!sourceInList ? 
								<>
									<Col xs={24} md={6} lg={6} className="titles">
										<label className="required"> {translations.fundSources} </label>
										<Select
											className="input-field"
											value={investorData.source}
											onChange={handleSelectData('source')}
											status={errors.source ? 'error' : ''}
											placeholder="Seleccione una opción"
										>
											{sourceOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
											<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
										</Select>
										<span className={`error ${errors.source ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
									</Col>
									<Col xs={24} md={6} lg={6} className="titles">
										<label className="required">{translations.otherSource}</label>
										<Input 
											name="otherSource"
											value={investorData.otherSource}
											onChange={handleChangeData}
											/>
									</Col>							
								</>
								:
								<>
									<Col xs={24} md={12} lg={12} className="titles">
										<label className="required"> {translations.fundSources} </label>
										<Select
											className="input-field"
											value={investorData.source}
											onChange={handleSelectData('source')}
											status={errors.source ? 'error' : ''}
											placeholder="Seleccione una opción"
										>
											{sourceOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
											<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
										</Select>
										<span className={`error ${errors.source ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
									</Col>
								</>
							}
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.anualIncome}</label>
								<Select
									className="input-field"
									value={investorData.incomes}
									onChange={handleSelectData('incomes')}
									status={errors.incomes ? 'error' : ''}
									placeholder="Seleccione una opción"
								>
									{incomeOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
								</Select>
								<span className={`error ${errors.incomes ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.ocupacion}</label>
								<Input type="text"
									name="ocupation"
									value={investorData.ocupation}
									onChange={handleChangeData}
									status={errors.ocupation ? 'error' : ''}
								/>
								<span className={`error ${errors.ocupation ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label>{translations.nombreEmpleador}</label>
								<Input type="text"
									name="employerName"
									value={investorData.employerName}
									onChange={handleChangeData}
								/>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<p>
									<label className="required"> {translations.familiarPrp} </label>
									<Tooltip placement="topLeft" title={translations.tooltipFamiliarPrp} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
								<Input type="text"
									name="familyPRP"
									value={investorData.familyPRP}
									onChange={handleChangeData}
									status={errors.familyPRP ? 'error' : ''}
								/>
								<span className={`error ${errors.familyPRP ? 'show' : null}`}>{translations.completeCampo}, de no corresponder escriba "NA"</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<p>
									<label className="required"> {translations.Prp} </label>
									<Tooltip placement="topLeft" title={translations.tooltipPrp} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
								<Input type="text"
									name="prp"
									value={investorData.prp}
									onChange={handleChangeData}
									status={errors.prp ? 'error' : ''}
								/>
								<span className={`error ${errors.prp ? 'show' : null}`}>{translations.completeCampo}, de no corresponder escriba "NA"</span>
							</Col>
						</Row>
						<Row className="titles">
							<Col xs={24} md={24} lg={24}>
								<p>
									<label> {translations.encabezadoFatca} </label>
									<Tooltip placement="topLeft" title={translations.tooltipFacta} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
							</Col>
							<Col xs={24} md={24} lg={24}>
								<Checkbox
									name="american"
									checked={investorData.american}
									value= {investorData.american}
									onChange={handleCheckedData}
								>
									<label>{translations.estadounidense}</label>
								</Checkbox>
							</Col>
						</Row>
						<Divider />
						{/* Esta sección, se muestra una única vez */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12} lg={12} className="titles">
								<p>
									<label className="required"> {translations.bancoDepositos} </label>
									<Tooltip placement="topLeft" title={translations.tooltipbanco} >
										<InfoCircleOutlined className="tooltip" />
									</Tooltip>
								</p>
								<Input type="text"
									name="bankDeposits"
									value={investorData.bankDeposits}
									onChange={handleChangeData}
									status={errors.bankDeposits ? 'error' : ''}
								/>
								<span className={`error ${errors.bankDeposits ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.direccionBanco}</label>
								<Input type="text"
									name="bankAddress"
									value={investorData.bankAddress}
									onChange={handleChangeData}
									status={errors.bankAddress ? 'error' : ''}
								/>
								<span className={`error ${errors.bankAddress ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.ibanFisica}</label>
								<Input type="text"
									name="iban"
									value={investorData.iban}
									onChange={handleChangeData}
									status={errors.iban ? 'error' : ''}
								/>
								<span className={`error ${errors.iban ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required"> {translations.swiftFisica}</label>
								<Input type="text"
									name="biC_SWIFT"
									value={investorData.biC_SWIFT}
									onChange={handleChangeData}
									status={errors.biC_SWIFT ? 'error' : ''}
								/>
								<span className={`error ${errors.biC_SWIFT ? 'show' : null}`}>{translations.completeCampo}</span>
							</Col>
						</Row>
					</>
					:
					<Result
						status="warning"
						title="Este contrato no existe o no está disponible"
						extra={
						<Button type="primary" key="console">
							{translations.inicio}
						</Button>
						}
					/>
				}
		</FormLayout>
	);
};

export default PhysicalInvestorForm;
