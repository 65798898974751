import { useState, useEffect } from "react";
import moment from "moment";
import { Popconfirm, Tooltip, message, notification } from "antd";
import { SendOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { RowUpdatedEvent } from 'devextreme/ui/data_grid';

import { IUseTableInRevision } from "./IUseTableInRevision";
import { ContractDTO } from "../Models/Contracts";
import { SharesType } from "../Models/SharesType";
import { useNavigate } from "react-router-dom";

const list = [
	{signatureProvider: 'Signaturit', id: 'Signaturit'}, 
	{signatureProvider: 'Docusign', id: 'Docusign'}
]

const AmountFormat = { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 };
export const useTableInRevision = ({ contractAPI, signaturitAPI, translations, investorAPI }: IUseTableInRevision) => {
    const [loading, setLoading] = useState<boolean>(false);
	const [contractListData, setContractListData] = useState<ContractDTO[]>([]);
    const [sharesTypes, setSharesTypes] = useState<SharesType[]>([]);
	const [canSend, setCanSend] = useState<boolean>(true);
	const [signatureProviderList, setSignatureProviders] = useState<any[]>([]);

	const navigate = useNavigate();

    useEffect(() => {
		getInRevisionContracts();
		getSharesTypes();
		setSignatureProviders(list)
    }, []);
    
    useEffect(() => {
		setLoading(false);
	}, [contractListData]);

    const getSharesTypes = () => {
		contractAPI.getShareTypes()
			.then((data: SharesType[]) => setSharesTypes(data))
	};

	const getInRevisionContracts = () => (
		contractAPI.getInRevisionContracts().then(res => {
			if(res.success){
				let data = res.data.map((contract: ContractDTO) => {
					contract.signatureDate = contract.signatureDate?.includes('0001-01-01') ? undefined : contract.signatureDate
					return contract
				});
				setContractListData(data);
			}
		})
	);

	const deleteContract = (contractId: number) => (
        contractAPI.deleteContract(contractId).then((res: any) => {
			if(res.success){
				notification.success({ message: translations.contractDeleted });
				getInRevisionContracts()
			}
			else{
				notification.error({ message: translations.contractNotDeleted });
			}
		})
	);

	const editarContrato = (contractId: number) => {
        sessionStorage.isEditing = true;
        sessionStorage.isCreating = false;
        navigate(`/FormContract?ContractId=${contractId}`);
	};

	const cellRenderEditIcon = (row: any) => {
	  return (
		  <div>
			  {<Tooltip placement="top" title={'Editar'}>
				  <button className="btnDelete" disabled={!canSend} onClick={() => editarContrato(row.data.id)} > 
					  <EditOutlined type="edit" /> 
				  </button>
			  </Tooltip> }
		  </div>
	  );
  };

	const cellRenderCancelIcon = (row: any) => {
		return	<Popconfirm
				placement="left"
				title="Está seguro de eliminar este contrato?"
				onConfirm={() => deleteContract(row.data.id)}
				onCancel={() => console.log('cancelado')}
				okText="Sí"
				cancelText="No"
				disabled={!canSend} 
			>
				<button className="btnDelete" disabled={!canSend} > 
					<DeleteOutlined />
				</button>
			</Popconfirm>
    };


	const createSignature = (id: number, signatureProvider: string) => {
		setLoading(true);
		signaturitAPI.createSignature(id, signatureProvider)
		message.success(`Contract with id ${id} is being generated` );
		setLoading(false);
		setTimeout(() => {
			getInRevisionContracts()
			setLoading(false)
		}, 2000);
		console.log('generated contract', id);
	}

	const sendContract = (id: number, signatureProvider: string) => {
		setLoading(true);
		signaturitAPI.sendContract(id, signatureProvider)
		message.success(`Contract with id ${id} is being sent` );
		setLoading(false);
		setTimeout(() => {
			getInRevisionContracts()
			setLoading(false)
		}, 2000);
		console.log('sent contract', id);
	}

    
    const cellRenderSendIcon = (row: any) => {
		let canSendContract = row.data.signatureProvider && row.data.idShareType;
		if(row.data.idContractState === 2){
			return (
				<Popconfirm 
					placement="left"
					title={'¿Está seguro que quiere enviar el contrato?'}
					okText={'Enviar'}
					cancelText={'Cancelar'}
					disabled={!canSendContract} 
					onConfirm={() => createSignature(row.data.id, row.data.signatureProvider)}>
						<button className="send-btn" disabled={!canSendContract} ><SendOutlined /> {translations.enviar} </button>
				</Popconfirm>
			)
		}
		if(row.data.idContractState === 8){
			return (
				<Popconfirm 
					placement="left"
					title={'¿Está seguro que quiere omitir la espera?'}
					okText={'Enviar'}
					cancelText={'Cancelar'}
					disabled={!canSendContract} 
					onConfirm={() => sendContract(row.data.id, row.data.signatureProvider)}>
						<button className="send-btn" disabled={!canSendContract} ><SendOutlined /> {translations.omitirEspera} </button>
				</Popconfirm>
			)
		}
	}
			
    const getFilteredSharesTypes = (data: ContractDTO) => {
		if (sharesTypes.filter((x: any) => x.sharesType === "Select...").length === 0) {
			sharesTypes.push({
				idSharesType: 0,
				sharesType: "Select...",
				idVehicle: 0,
				isin: "string",
				active: true,
				refDate: "",
				minRange: 0,
				maxRange: 0,
				days: 0,
				idPlacementAgent: 0,
				priority: "",
				SubscriptionFee: false
			});
		}
		if (data) {
			return sharesTypes.filter((x: any) => x.idVehicle === data.vehicleId || x.sharesType === "Select...");
		} else {
			return sharesTypes;
		}
	};

    const updateContract = async(e: RowUpdatedEvent) => {
			e.data.signatureDate = e.data.signatureDate ?? '0001-01-01' 
			delete e.data.personType;
			if(e.data.idShareType){
				await investorAPI.updateShareType(e.data.id, e.data.idShareType)
					.then((res: any) => {
						if (res.success) {
							message.success(`Shares type for contract with id ${e.data.id} updated` );
						} else {
							message.error(`There was an error saving Shares type for contract with id ${e.data.id}`);
						}
					});
			}
			if(e.data.signatureProvider){
				await contractAPI.updateContract(e.data)
					.then((res: any) => {
						if (res.success) {
							message.success(`Contract with id ${e.data.id} updated`);
						} else {
							message.error(`There was an error saving Contract with id ${e.data.id}`);
						}
					});
				}
				getInRevisionContracts()
   		};

	const checkDate = (date: any) => {
		const format = /^\d{4}-\d{2}-\d{2}$/;
		if (!format.test(date)) {
		  return false;
		}
		const [year, month, day] = date.split('-').map(Number);  
		return(
			year >= 1900 &&
			year <= 9999 &&
			month >= 1 &&
			month <= 12 &&
			day >= 1
		)
	}

	const onRowValidating = (e: any) => {
		if(e.newData.signatureDate){
			let isValid = checkDate(e.newData.signatureDate)
			e.isValid = isValid;

			if(!isValid){
				notification.error({ message:  `Please use format 'YYYY-MM-DD' for date ${e.newData.signatureDate}` });
			}else{
				setCanSend(true)
			}
		}
	}

	const cellRenderDate = (date: any) => {
		if(!date || date.includes('0001-01-01')){
			return <p className="datePlaceholder">YYYY-MM-DD</p>
		}else{
			return moment(date).format('YYYY-MM-DD');
		}
	}

	const cellRenderSendDate = (date: any, state: any) => {
		if(state === 8 && date){
			return moment(date).format('DD/MM/YYYY HH:mm');
		}
	}

	const onCellPrepared = (e: any) => {
		if (e.rowType === 'data') {
			const { idContractState } = e.data;	
			if (idContractState === 8) {
				e.cellElement.style.color = 'rgb(255, 131, 26)';
			}
		}
	};


    return{
        contractListData,
        cellRenderSendIcon,
        loading,
        AmountFormat,
        getFilteredSharesTypes,
        updateContract,
		onRowValidating,
		cellRenderDate,
		cellRenderCancelIcon,
		cellRenderEditIcon,
		setCanSend,
		signatureProviderList,
		onCellPrepared,
		cellRenderSendDate
    };
};