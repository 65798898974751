import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import moment from "moment";

import { IUsePhysicalInvestorForm } from "./IUsePhysicalInvestorForm";

import { Utils } from "../Utils";
import { emptyInvestor } from "../Assets/Data/EmptyValues";
import { InvestorDTO } from "../Models/InvestorAPI";

export const usePhysicalInvestorForm = ({
	translations,
	investorAPI,
	unlockNextStep,
	contractAPI
}: IUsePhysicalInvestorForm) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [noContent, setNoContent] = useState<boolean>(false);
	const [nationalitiesList, setNationalitiesList] = useState<any[]>([]);
	const [investorData, setInvestorData] = useState<InvestorDTO>(emptyInvestor);;
	const [contractLanguage, setContractLanguage] = useState<string>('es');

	const [errors, setErrors] = useState<any>({
		birthDate: false,
		birthPlace: false,
		nationality: false,
		mainResidence: false,
		emailContact: false,
		phone: false,
		documentType: false,
		document: false,
		countryTaxResidence: false,
		taxIdentification: false,
		ocupation: false,
		sourceOfFunds: false,
		familyPRP: false,
		prp: false,
		bankDeposits: false,
		bankAddress: false,
		iban: false,
		biC_SWIFT: false,
		situation: false,
        incomes: false,
        source: false,
		sector: false,
		lei: false,
		docValidThru: false,
		documentFormat: false,
		documentExpirationInvalid: false,
	});

	const [activityInList, setActivityInList] = useState<boolean>(true)
	const [sourceInList, setSourceInList] = useState<boolean>(true)

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
			getNationalities();
		}
	}, [searchParams.get('ContractId')]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await investorAPI.getInvestors(ContractId).then((res: any) => {
			if(res.success){
				if(res.data.length > 0 ) {
					if(res.data[0].dni) res.data[0].documentType = 'dni';
					if(res.data[0].nif) res.data[0].documentType = 'nif';
					if(res.data[0].passport) res.data[0].documentType = 'passport';
					if(res.data[0].residenceCard) res.data[0].documentType = 'residenceCard';
					setInvestorData(res.data[0]);
				}
			} else {
				setNoContent(true);
			}
			setLoading(false);
		}).catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
		contractAPI.getContract(ContractId).then(res => {
			if(res.success){
				if(res.data?.idLanguage){
					setContractLanguage(res.data.idLanguage === 1 ? 'es' : 'en');
				}
			}
		})
	};

	const handleChangeData = (e: any) => {
		const { name, value } = e.target;
		setErrors((prevState: any) => ({
			...prevState,
			[name]: false
		}));
		setInvestorData((prevState: InvestorDTO) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleChangeDate = (date: any) => {
		setInvestorData((prevState: InvestorDTO) => ({
			...prevState,
			docValidThru: date ? date.format('YYYY-MM-DD') : undefined
		}));
		setErrors((prevState: any) => ({
			...prevState,
			docValidThru: false
		}));
	};

	const onChangeBirthDate = (date: any) => {
		setInvestorData((prevState: InvestorDTO) => ({
			...prevState,
			birthDate: date ? date.format('YYYY-MM-DD') : undefined
		}));
		setErrors((prevState: any) => ({
			...prevState,
			birthDate: false
		}));
	};

	const handleSelectData = (field: string) => (value: string) => {
		field === 'sector' && setActivityInList(value !== 'Otro');
		field === 'source' && setSourceInList(value !== 'Otro');
		setErrors((prevState: any) => ({
			...prevState,
			[field]: false
		}));
		setInvestorData((prevState: InvestorDTO) => ({
			...prevState,
			[field]: value
		}));
	};

	const validateData = (modifyErrors: boolean) => {
		let isOK = true;
		if (!investorData.birthDate) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, birthDate: true }));
			isOK = false;
		}
		if (!investorData.birthPlace) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, birthPlace: true }));
			isOK = false;
		}
		if (!investorData.nationality) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, nationality: true }));
			isOK = false;
		}
		if (!investorData.mainResidence) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, mainResidence: true }));
			isOK = false;
		}
		if (!investorData.emailContact || !Utils.validateEmail(investorData.emailContact)) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, emailContact: true }));
			isOK = false;
		}
		if (!investorData.phoneNumber) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, phoneNumber: true }));
			isOK = false;
		}
		if (!investorData.phonePrefix) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, phonePrefix: true }));
			isOK = false;
		}
		if (!investorData.documentType) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, documentType: true }));
			isOK = false
		}
		const document = investorData.dni || investorData.nif || investorData.passport || investorData.residenceCard;
		if (!document) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, document: true }));
			isOK = false;
		} else if (!/^[a-zA-Z0-9]*$/g.test(document!)) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, documentFormat: true }));
			isOK = false;
		}
		if (!investorData.docValidThru) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, docValidThru: true }));
			isOK = false;
		} else if (moment(investorData.docValidThru).isBefore(moment())) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, documentExpirationInvalid: true }));
			isOK = false;
		}
		if (!investorData.countryTaxResidence) {
			modifyErrors &&setErrors((prevState: any) => ({ ...prevState, countryTaxResidence: true }));
			isOK = false;
		}
		if (!investorData.taxIdentification) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, taxIdentification: true }));
			isOK = false;
		}
		if (!investorData.ocupation) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, ocupation: true }));
			isOK = false;
		}
		if ((investorData.sector === 'Otro' && !investorData.otherSector) || !investorData.sector) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, sector: true }));
			isOK = false;
		}
		if (!investorData.familyPRP) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, familyPRP: true }));
			isOK = false;
		}
		if (!investorData.prp) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, prp: true }));
			isOK = false;
		}
		if (!investorData.bankDeposits) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, bankDeposits: true }));
			isOK = false;
		}
		if (!investorData.bankAddress) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, bankAddress: true }));
			isOK = false;
		}
		if (!investorData.iban) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, iban: true }));
			isOK = false;
		}
		if (!investorData.biC_SWIFT) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, biC_SWIFT: true }));
			isOK = false;
		}
		if (!investorData.situation) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, situation: true }));
			isOK = false;
		}
		if (!investorData.incomes) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, incomes: true }));
			isOK = false;
		}
		if ((investorData.source === 'Otro' && !investorData.otherSource) || !investorData.source) {
			modifyErrors && setErrors((prevState: any) => ({ ...prevState, source: true }));
			isOK = false;
		}
		return isOK;
	};

	const saveData = () => {
		setLoading(true);
		let obj = {...investorData}
		if (validateData(true)) {
			obj.sector = obj.sector === 'Otro' ? obj.otherSector : obj.sector;
			obj.source = obj.source === 'Otro' ? obj.otherSource : obj.source;
			obj.docValidThru = moment(obj.docValidThru).format('YYYY-MM-DD');
			obj.birthDate = moment(obj.birthDate).format('YYYY-MM-DD');
			investorAPI.updateInvestors([obj]).then((res: any) => {
				if(res.success){
					message.success(translations.datosGuardados);
					unlockNextStep();
				}else{
					message.error(translations.messageError);
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	const handleCheckedData = (e: any) => {
		const { name } = e.target;
		setInvestorData(prevState => ({
			...prevState,
			[name]: e.target.checked
		}));
	};

	const getNationalities = () => {
		investorAPI.getCountries().then((res: any) => {
			if (res !== "") {
				setNationalitiesList(res);
			}
		})
		.catch((err: any) => {
			console.log(err);
		});
	};

	return {
		loading,
		noContent,
		errors,
		investorData,
		nationalitiesList,
		activityInList,
		sourceInList,
		onChangeBirthDate,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		handleCheckedData,
		saveData,
		validateData,
		contractLanguage
	};
};