import {
	Button,
	Col,
	Input,
	InputNumber,
	Row,
	Select,
	Tooltip,
	Result,
	DatePicker,
	Popconfirm,
} from "antd";
import {
	InfoCircleOutlined,
	MinusCircleOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { ContractAPI } from "../../../Api/ContractAPI";
import { useInvestorData } from "../../../Hooks/useInvestorData";

import { IInvestorData } from "./IInvestorData";

import FormLayout from "../../FormLayout/FormLayout";
import { InvestorAPI } from "../../../Api/InvestorAPI";

import { TemplateAPI } from "../../../Api/TemplatesAPI";

import "./InvestorData.scss";

const { Option } = Select;

const InvestorData = ({
	baseUrl,
	selectedPersonType,
	translations,
	institutionData,
	getAuthToken,
	setContractInstitution,
	setSelectedPersonType,
	unlockNextStep,
	userIdInstitution,
}: IInvestorData) => {
	const contractAPI = ContractAPI(baseUrl, getAuthToken);
	const investorsAPI = InvestorAPI(baseUrl, getAuthToken);
	const templatesAPI = TemplateAPI(baseUrl, getAuthToken);

  	let isCreand = userIdInstitution === 1231;

	const {
		loading,
		noContent,
		contractErrors,
		investorsErrors,
		contractData,
		error,
		onChangeVehicle,
		onChangePersonType,
		isAllowedAmount,
		onChangeCommitment,
		saveData,
		onChangeLanguage,
		onChangeInvestorsData,
		investorsData,
		addInvestor,
		onDeleteInvestor,
		onChangeCreandData,
		nationalitiesList
	} = useInvestorData({
		translations,
		contractAPI,
		investorsAPI,
		institutionData,
		templatesAPI,
		selectedPersonType,
		setSelectedPersonType,
		unlockNextStep,
		userIdInstitution,
		isCreand
	});

  	return (
    	<>
			{!noContent ? (
				<FormLayout
					translations={translations}
					loading={loading}
					title={translations.datosFirmantes}
					subTitle={translations.completarDatos}
					onClick={saveData}
					nextStep={unlockNextStep}
					enableNextStep={(contractData.id && InvestorData.length > 0) ? true : false}
					confirm={contractData.id ? null : translations.confirmContract }
					disabledSaveButton={
						(contractData.idContractState !== 1 &&
						contractData.idContractState !== 2) ||
						error || contractErrors.noTemplate
					}
				>
					<Row gutter={[16, 16]} className="row-wtdh">
						<Col xs={24} md={12} lg={12}>
							<label className="required">
								{translations.vehiculoContratar}
							</label>
							<Select
								className="input-field"
								value={contractData.vehicleId || undefined}
								placeholder={translations.seleccionVehiculo}
								onChange={onChangeVehicle}
								disabled={contractData.id !== 0}
								status={contractErrors.noVehicle ? "error" : ""}
							>
								{institutionData?.institutionValues.map((x) => (
									<Option value={x.vehicleId}>{x.vehicleLegalName}</Option>
								))}
							</Select>
							<span className={`error ${contractErrors.noVehicle ? "show" : null}`}>
								{translations.seleccionVehiculo}
							</span>
						</Col>
						{/* CREAND DATA BP AND CONTAINER */}
						{isCreand && (
							<>
								<Col xs={24} md={6} lg={6}>
									<label className="required"> BP</label>
									<InputNumber
										className="input-field"
										placeholder="BP"
										name="bp"
										value={contractData.bp}
										disabled={contractData.id !== 0}
										status={contractErrors.bp ? "error" : ""}
										onChange={(e: any) => onChangeCreandData(e, "bp")}
									/>
									<span className={`error ${contractErrors.bp ? "show" : null}`}>
										{translations.noBP}
									</span>
								</Col>
								<Col xs={24} md={6} lg={6}>
									<label className="required"> Container</label>
									<InputNumber
										className="input-field"
										placeholder="Container"
										name="container"
										disabled={contractData.id !== 0}
										status={contractErrors.container ? "error" : ""}
										value={contractData.container}
										onChange={(e: any) => onChangeCreandData(e, "container")}
									/>
									<span className={`error ${contractErrors.container ? "show" : null}`}>
										{translations.noContainer}
									</span>
								</Col>
							</>
						)}
					</Row>
					<Row gutter={[16, 0]} className="row-wtdh">
						<Col xs={12} md={6} lg={6}>
							<label className="required"> {translations.tipoPersona}</label>
							<Select
								className="input-field"
								value={contractData.idPersonType}
								onChange={onChangePersonType}
								disabled={contractData.id !== 0}
							>
								<Option value={2}>{translations.juridica}</Option>
								<Option value={1}>{translations.fisica}</Option>
							</Select>
						</Col>
						<Col xs={12} md={6} lg={6}>
							<label className="required"> {translations.compromiso}</label>
							<InputNumber
								className="input-field"
								name="commitment"
								value={contractData.commitment}
								onBlur={(e: any) => isAllowedAmount(e.target.value)}
								onChange={onChangeCommitment}
								status={contractErrors.noCommitment ? "error" : ""}
							/>
							<span className={`error ${contractErrors.noCommitment ? "show" : null}`}>
								{translations.messageCompromiso}
							</span>
						</Col>
						<Col xs={12} md={6} lg={6}>
							<label className="required"> {translations.language}</label>
							<Select
								className="input-field"
								value={contractData.idLanguage}
								onChange={onChangeLanguage}
								disabled={contractData.id !== 0}
							>
								<Option value={1}>{translations.spanish}</Option>
								<Option value={2}>{translations.english}</Option>
								<Option value={3}>{translations.portuguese}</Option> 
							</Select>
						</Col>
						<Col xs={12} md={6} lg={6}>
							<p>
								<label className="required">
									{translations.personasFirmantes}
								</label>
								<Tooltip
									placement="topLeft"
									title={translations.tootltipPersonasFirmantes}
								>
									<InfoCircleOutlined className="tooltip" />
								</Tooltip>
							</p>
							<div className="numSignaturies">
								<span>{contractData.numSignatories}</span>
								<Button type="text" className="save-btn" onClick={addInvestor} disabled={contractData.id !== 0}>
									<PlusCircleOutlined />
								</Button>
							</div>
						</Col>
					</Row>
					{contractErrors.noTemplate &&
						<span className={`error-big ${contractErrors.noTemplate ? "show" : null}`}>
							{translations.errorTemplate}
						</span>
					}
					{/* Investors */}
					<Row className="investor-list">
						{contractData.numSignatories > 0 &&
							investorsData.map((_: any, index: number) => (
								<div className="subitem-line">
									<h3>
										{translations.datosFirmante} {index + 1}
									</h3>
									<Row gutter={[16, 8]} justify="start">
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.nombreFirmante}
											</label>
											<Input
												className="input-field"
												value={investorsData[index].name}
												onChange={(e) =>
													onChangeInvestorsData(index, e.target.value, "name")
												}
												placeholder={translations.nombreFirmante}
												status={investorsErrors[index].noInvestorsData && !investorsData[index].name? "error": ""}
											/>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.apellidosFirmante}
											</label>
											<Input
												className="input-field"
												value={investorsData[index].lastName}
												onChange={(e) =>
													onChangeInvestorsData(index,e.target.value,"lastName")
												}
												placeholder={translations.apellidosFirmante}
												status={investorsErrors[index].noInvestorsData &&!investorsData[index].lastName? "error": ""}
											/>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.documentType}
											</label>
											<Select
												className="input-field"
												value={investorsData[index].documentType}
												onChange={(e) =>
													onChangeInvestorsData(index, e, "documentType")
												}
												placeholder={translations.documentType}
												status={investorsErrors[index].noInvestorsData &&!investorsData[index].documentType? "error": ""}
											>
												<Option value="dni">DNI</Option>
												<Option value="nif">NIF</Option>
												<Option value="passport">{translations.passport}</Option>
												<Option value="residenceCard">{translations.residentCard}</Option>
											</Select>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.documentNumber}
											</label>
											{investorsData[index].documentType === "dni" && 
												<Input
													className="input-field"
													value={investorsData[index].dni}
													onChange={(e) => onChangeInvestorsData(index,e.target.value,"dni")}
													placeholder={translations.documentNumber}
													status={
														investorsErrors[index].noInvestorsData
														&& investorsErrors[index].notValidDocument
														&& !investorsData[index].dni
															? "error" : ""
													}
												/>
											}
											{investorsData[index].documentType === "nif" &&
												<Input
													className="input-field"
													value={investorsData[index].nif}
													onChange={(e) => onChangeInvestorsData(index,e.target.value,"nif")}
													placeholder={translations.documentNumber}
													status={
														investorsErrors[index].noInvestorsData
														&& investorsErrors[index].notValidDocument
														&& !investorsData[index].nif
															? "error" : ""
													}
												/>
											}
											{investorsData[index].documentType === "passport" &&
												<Input
													className="input-field"
													value={investorsData[index].passport}
													onChange={(e) => onChangeInvestorsData(index,e.target.value,"passport")}
													placeholder={translations.documentNumber}
													status={
														investorsErrors[index].noInvestorsData
														&& investorsErrors[index].notValidDocument
														&& !investorsData[index].passport
															? "error" : ""
													}
												/>
											}
											{investorsData[index].documentType === "residenceCard" &&
												<Input
													className="input-field"
													value={investorsData[index].residenceCard}
													onChange={(e) => onChangeInvestorsData(index,e.target.value,"residenceCard")}
													placeholder={translations.documentNumber}
													status={
														investorsErrors[index].noInvestorsData
														&& investorsErrors[index].notValidDocument
														&& !investorsData[index].residenceCard
															? "error" : ""
													}
												/>
											}
											{!investorsData[index].documentType &&
												<Input
													className="input-field"
													disabled
													placeholder={translations.documentNumber}
												/>
											}
											<span className={`error ${investorsErrors[index].notValidDocument ? 'show' : null}`}>{translations.invalidIdDocument}</span>
										</Col>
										<Col xs={24} md={6} lg={6} className="titles">
											<label className="required"> {translations.expirationDate}</label>
											<DatePicker
												allowClear={false}
												className="input-field"
												name="docValidThru"
												value={investorsData[index].docValidThru ? moment(investorsData[index].docValidThru) : undefined}
												format="DD/MM/YYYY"
												onChange={(e) => onChangeInvestorsData(index, moment(e).format("YYYY-MM-DD"), "docValidThru")}
												status={
													investorsErrors[index].noInvestorsData
													&& investorsErrors[index].notValidExpirationDate
													&& !investorsData[index].docValidThru
														? "error" : ""
												}
											/>
											<span className={`error ${investorsErrors[index].notValidExpirationDate ? 'show' : null}`}>{translations.invalidDate}</span>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.email}
											</label>
											<Input
												className="input-field"
												value={investorsData[index].emailContact}
												onChange={(e) =>
													onChangeInvestorsData(
														index,
														e.target.value,
														"emailContact"
													)
												}
												placeholder={translations.email}
												status={investorsErrors[index].noValidEmail? "error": ""}
											/>
											<span className={`error ${investorsErrors[index].noValidEmail ? "show" : null}`}>
												{translations.completeEmailValido}
											</span>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.fechaNacimiento}
											</label>
											<DatePicker
												className="input-field"
												value={
													investorsData[index].birthDate
														? moment(investorsData[index].birthDate)
														: undefined
												}
												onChange={(e) =>
													onChangeInvestorsData(
														index,
														moment(e).format("YYYY-MM-DD"),
														"birthDate"
													)
												}
												allowClear={false}
												placeholder={translations.fechaNacimiento}
												format="DD/MM/YYYY"
												status={
													investorsErrors[index].noInvestorsData && !investorsData[index].birthDate
														? "error"
														: ""
												}
											/>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required"> {translations.nacionalidad}</label>
											<Select
												className="input-field"
												value={investorsData[index].nationality}
												onChange={(e) =>
													onChangeInvestorsData(
														index,
														e,
														"nationality"
													)
												}
												status={
													investorsErrors[index].noInvestorsData && !investorsData[index].nationality
														? "error"
														: ""
												}
												placeholder="Seleccione una opción"
												showSearch
												filterOption={(input, option) =>
												(option!.children as unknown as string).toString().toLowerCase().includes(input.toLowerCase())
												}
											>
												{nationalitiesList.map((nationality: any)=> {
													return <Option value={nationality.name_lang2}>{nationality.name_lang2}</Option>
												})}
											</Select>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.prefix}
											</label>
											<Input
												className="input-field"
												value={investorsData[index].phonePrefix}
												onChange={(e) =>
													onChangeInvestorsData(
														index,
														e.target.value,
														"phonePrefix"
													)
												}
												placeholder={"+34"}
												status={
													investorsErrors[index].noInvestorsData && !investorsData[index].phonePrefix
														? "error"
														: ""
												}
											/>
										</Col>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
											{translations.telefonoContacto}
											</label>
											<Input
												className="input-field"
												value={investorsData[index].phoneNumber}
												onChange={(e) =>
													onChangeInvestorsData(
														index,
														e.target.value,
														"phoneNumber"
													)
												}
												placeholder={"000 000 000"}
												status={
													investorsErrors[index].noInvestorsData && !investorsData[index].phoneNumber
														? "error"
														: ""
												}
											/>
										</Col>
										<Col xs={12} md={6} lg={6} className="delete-investor">
											{index > 0 && 
												<Popconfirm 
													title= {translations.questionDeleteInvestor}
													okText={translations.deleteInvestor}
													cancelText={translations.no}
													placement="left"
													disabled={contractData.id !== 0}
													onConfirm={() => onDeleteInvestor(index)}
												>
													<Button danger type="text">
														<MinusCircleOutlined /> {translations.deleteInvestor} {index+1}
													</Button>
												</Popconfirm>
											}
										</Col>
									</Row>
									<span
										className={`error ${
											investorsErrors[index].noInvestorsData ? "show" : null
										}`}
									>
										{translations.messageInvestors}
									</span>
								</div>
							)
						)}
					</Row>
					<Row className="data-protection">
						<strong>{translations.clausulaProteccionTitulo}</strong>
						<span>{translations.clausulaProteccion}</span>
					</Row>
				</FormLayout>
			) : (
				<Result
					status="warning"
					title={translations.messageContrato}
					extra={
						<Button type="primary" key="console">
							{translations.inicio}
						</Button>
					}
				/>
			)}
    	</>
  	);
};

export default InvestorData;
